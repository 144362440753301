/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
  List with check marks, with items divided by a borer:

  <ul class="hooray-list">
    <li>List item here</li>
  </ul>
*/

.admin-include {
  padding: 0.5rem;
  background: repeating-linear-gradient(45deg, #ececec, #ececec 10px, transparentize($danger-color, 0.8) 10px, transparentize($danger-color, 0.8) 20px);

  justify-items: center;

  form {
    display: inline;
  }

  .button {
    margin: 0;
  }
}
