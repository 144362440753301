/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
  <div class="sponsorship-intro">
    <div class="sponsorship-intro__text">
    // Intro text here
    </div>
    <div class="sponsorship-intro__donations">
      // donations box here
    </div>
  </div>
*/

.sponsorship-intro {
  display: flex;

  @media only screen and (max-width: $desktop) {
    display: block;
  }

  &__text {
    width: 70%;
    padding-right: $spacing-unit * 2;

    @media only screen and (max-width: $desktop) {
      width: 100%;
      padding: 0;
      margin-bottom: $spacing-unit * 2;
    }

    h1 {
      @include h1-title;
      padding-bottom: 0;
    }

    .lede-paragraph {
      margin: $spacing-unit 0;
      padding: 0;
    }
  }

  &__donations {
    width: 30%;
    padding-bottom: 120px !important;

    @media only screen and (max-width: $desktop) {
      width: 100%;
    }
  }
}
