/*!
* Licensed under the Apache License, Version 2.0 (the "License");
* you may not use this file except in compliance with the License.
* You may obtain a copy of the License at
*
* http://www.apache.org/licenses/LICENSE-2.0
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS,
* WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
* See the License for the specific language governing permissions and
* limitations under the License.
*/

/*
A custom styled code block.

 <code class="code-block">
   Code here
 </code>

 <pre class="code-block">
   Code here
 </pre>
*/

.code-block {
  display: block;
  padding: $half-spacing-unit;
  margin-bottom: $half-spacing-unit;
  word-break: break-all;
  direction: ltr;
  overflow-x: auto;
}
