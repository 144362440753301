/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
  Main container for the site:
  <div class="site-container"></div>
*/

.site-container {
  @include site-container;
}

/*
  Container for vertical tabs
*/

.tabs-container {
  @include site-container;

  @media only screen and (max-width: $tablet) {
    padding: 5px;
  }
}

/*
  Narrow container for text only pages:
  <div class="narrow-container"></div>
*/

.narrow-container { // Use for single column content pages
  @include centered-container($narrow-container);
  padding: 0 $spacing-unit;

  @media screen and (max-width: $small-tablet) {
    padding: 0 $half-spacing-unit;
  }
}
