/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
  A form field, with label, input, and help text.

  <div class="gravatar-form">
    <img class="gravatar-form__image"/>
    <div class="gravatar-form__content">
      content here
    </div>
  </div>
*/

.gravatar-form {
  display: flex;
  align-items: center;

  &__image {
    margin-right: $half-spacing-unit;
  }

  &__content {
    max-width: 500px;
  }

  @media screen and (max-width: $small-tablet) {
    align-items: start;

    &__image {
      max-width: 90px;
    }
  }

  @media screen and (max-width: $mobile) {
    display: block;

    &__image {
      margin-right: 0;
    }

    &__content {
      max-width: 100%;
      margin-top: 15px;
    }
  }
}
