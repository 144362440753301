/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
  A tree of checkboxes with sub levels.

  <div class="checkbox-tree">
    <ul>
      <li>
        <input class="checkbox-tree__checkbox" id="mycheckbox">
        <label class="checkbox-tree__label" for="mycheckbox"></label>
        <!-- more tree here -->
      </li>
    </ul>
  </div>
*/

.checkbox-tree {
  margin-left: 8px;

  ul {
    margin: 5px 0 0 12px;
    list-style: none;
    position: relative;
    padding: 0 0 0 5px;
  }

  li {
    position: relative;
    padding: 0 0 0 5px;

    &::before {
      content: "";
      position: absolute;
      left: -12px;
      top: 13px;
      width: 12px;
      height: 0;
      border-top: 2px solid $light-grey;

      @media only screen and (max-width: $desktop) {
        border-top-color: $primary-color-light;
      }
    }

    &::after {
      content: "";
      position: absolute;
      left: -12px;
      height: 100%;
      width: 0;
      top: -2px;
      border-left: 2px solid $light-grey;

      @media only screen and (max-width: $desktop) {
        border-left-color: $primary-color-light;
      }
    }
  }

  ul > li:last-child::after {
    height: 17px;
  }

  &__checkbox {
    position: relative;
    top: 3px;
  }

  @media only screen and (max-width: $desktop) {
    &__checkbox[type="checkbox"]:active,
    &__checkbox[type="checkbox"]:focus {
      outline: 1px solid $white;
    }
  }

  &__label {
    font-size: $small-font-size;
    cursor: pointer;
    line-height: 1;
  }
}
