/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
  The sponsor footer.

  <div class="sponsors">
    <p class="sponsors__title">
      // Title
    </p>
    <div class="sponsors__divider"></div>

    <a class="sponsors__sponsor">
      <img class="sponsors__image">
      <span class="sponsors__name"></span>
      <span class="sponsors__service"></span>
    </a>
  </div>
*/

.sponsors {
  text-align: center;
  border-top: 1px solid darken($primary-color, 2);
  background-color: $primary-color;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; // place the entire div in center page
  padding: $half-spacing-unit;

  &__title {
    display: none;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid transparentize($white, 0.8);
    color: $white;

    @media only screen and (max-width: $small-tablet) {
      display: block;
      flex: 1;
    }
  }

  &__divider {
    flex-basis: 100%;
    height: 0;
  }

  &__sponsor {
    display: inline-grid;
    grid-template-rows: fit-content(40%);
    text-align: center;
    padding: $half-spacing-unit 13px 10px 13px;
    opacity: 0.95;
    @include link-without-underline;
    @include link-focus-state($white);

    &:hover {
      opacity: 1;

      .sponsors__image {
        opacity: 1;
      }
    }

    @media only screen and (max-width: $tablet) {
      padding: 7px 10px;
    }

    @media only screen and (max-width: $small-tablet) {
      padding: 5px 7px;
    }
  }

  &__image {
    justify-self: center;
    max-width: 100px;
    opacity: 0.8;

    @media only screen and (max-width: $tablet) {
      display: none;
    }
  }

  &__name,
  &__service {
    max-width: 100px;
    align-self: end;
    font-size: 0.75rem;
    color: $white;
  }

  &__name {
    font-weight: 600;
    margin-top: 10px;

    @media only screen and (max-width: $small-tablet) {
      margin: 0;
      font-weight: normal;
    }
  }

  &__service {
    @media only screen and (max-width: $small-tablet) {
      display: none;
    }
  }
}
