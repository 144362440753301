/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
  Footer:

  <div class="footer">
    <div class="footer__icon">
      // footer icon image goes here
    </div>
    <div class="footer__menus"> // menu wrapper
      <div class="footer__menu">
        // H2: Menu title
        <nav aria-label="menu label">
          <ul>
            <li><a>Menu Link</a></li>
          </ul>
        </nav>
      </div>
    </div>

    <hr class="footer__divider">

    <div class="footer__text">
      // copyright, disclaimers,etc.
    </div>
  </div>
*/

.footer {
  background-color: lighten($primary-color, 2);
  color: $white;
  padding: 60px 0;
  width: 100%;

  @media only screen and (max-width: $mobile) {
    padding-top: 40px 0;
  }

  &__logo {
    margin-bottom: $spacing-unit;
    text-align: center;
  }

  &__menus {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: $spacing-unit;

    @media only screen and (max-width: $mobile) {
      margin-bottom: 10px;
    }
  }

  &__menu {
    flex-basis: 220px;
    flex-shrink: 0;
    margin: 0 $half-spacing-unit 40px;
    padding: 0;

    h2 {
      color: $white;
      margin-bottom: 5px;
      text-align: center;
    }

    ul {
      margin: 0;
      padding: 0;
    }

    li {
      padding: 2px 0;
      list-style: none;
      text-align: center;

      a {
        text-decoration: none;
        @include white-underlined-link;
      }
    }
  }

  &__divider {
    border: 1px solid transparentize($white, 0.7);
    margin: 0 auto $spacing-unit;
    width: 200px;
    background: none;
    height: 0;
  }

  &__text {
    color: transparentize($white, 0.05);
    font-size: 0.9rem;
    margin: auto;
    max-width: 600px;
    text-align: center;
    padding: 0 20px;

    a {
      @include white-underlined-link;
    }
  }
}
