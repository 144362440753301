/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
  Verified tag and session

  <div class="verified"></div>
*/

.verified, .unverified {
  ul {
    padding-top: 0;
  }

  h3 {
    padding-bottom: 0;
  }

  h6 {
    padding-bottom: 5px;
    padding-top: 15px;
  }

  small {
    color: #555;
  }

  .check {
    color: #148024;
  }
}
