/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
  <div class="sponsorship-benefits">
    <div class="sponsorship-benefits__benefit">
    // Benefit text here
    </div>
  </div>
*/

.sponsorship-benefits {
  display: flex;

  @media only screen and (max-width: $desktop) {
    display: block;
    padding: $spacing-unit 0 0;
  }

  &__benefit {
    width: 33%;
    padding: $spacing-unit;

    @media only screen and (max-width: $desktop) {
      width: 100%;
      padding: 0 0 $spacing-unit;
    }
  }
}
