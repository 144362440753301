/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
  Navigation breadcrumbs.

  <nav aria-label="Breadcrumb" class="breadcrumbs">
    <ul>
      <li class="breadcrumbs__breadcrumb"></li>
      <li class="breadcrumbs__breadcrumb"></li>
    </ul>
  </nav>
*/


.breadcrumbs {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: $half-spacing-unit;
  margin-top: $spacing-unit;
  padding-left: $half-spacing-unit;
  padding-right: $half-spacing-unit;

  + div {
    margin-top: $half-spacing-unit;
  }

  @media only screen and (max-width: $tablet) {
    display: none;
  }

  ul {
    margin: 0;
    padding: 0;
    @include clearfix;
  }

  &__breadcrumb {
    color: grayscale($primary-color);
    display: inline-block;
    float: left;
    list-style-type: none;

    &:first-child {
      color: $text-color;
    }

    a {
      @include link-without-underline;
    }

    .fa {
      margin-left: 0.1em;
      margin-right: 0.1em;
    }

    &:not(:last-child)::after {
      content: "\f054";
      font-family: "Font Awesome 6 Free";
      font-weight: 900;
      margin-left: 4px;
      margin-right: 6px;
      color: $light-grey;
      font-size: 12px;
    }
  }
}
