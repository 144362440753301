/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
  Panel for filtering search results.
  On smaller resolutions, this panel is:
    - hidden by default
    - styled with a blue background and white content
    - shown over other content after being launched by a button

  <div class="filter-panel">
    <button type="button" class="filter-panel__close -js-close-panel" aria-label="Close panel">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
    // Panel content here
  </div>
*/

.filter-panel {
  &__close {
    display: none;
  }

  @media only screen and (max-width: $desktop) {
    display: none;
    margin: 0;
    padding: 25px;
    overflow: auto;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $primary-color;
    width: 300px;
    max-width: 100%;
    z-index: index($z-index-scale, "filter-panel");

    &__close {
      display: inline-block;
      @include dismiss-button;
      color: $white;
      @include link-focus-state($white);
    }

    h2,
    label,
    a {
      color: $white;
    }

    a {
      color: $white;
      @include white-underlined-link;
    }
  }
}
