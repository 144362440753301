/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
  A lede paragraph.  Use to highlight important sections in the text.

  <p class="lede-paragraph">I will be larger than my counterparts</p>
*/

.lede-paragraph {
  font-weight: $bold-font-weight;
  font-size: 1.2rem;
  color: lighten($text-color, 10);
}
