/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
  Column that is 50% wide:
  <div class="col-grid">
    <div class="col-half"></div>
  </div>
*/

.col-grid {
  @include grid-container;
}

.col-half {
  grid-column: span 6;

  &:last-of-type {
    margin: 0;
  }

  @media only screen and (max-width: $tablet) {
    float: none;
    margin: 0;
    width: 100%;

    &:first-of-type {
      margin-bottom: $spacing-unit;
    }
  }
}
