/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
  List with check marks, with items divided by a borer:

  <ul class="hooray-list">
    <li>List item here</li>
  </ul>
*/

.hooray-list {
  margin-left: 0;
  border-top: 1px dotted $border-color;

  li {
    border-bottom: 1px dotted $border-color;
    padding: 10px 0 10px 30px;
    list-style-type: none;
    position: relative;

    &::before {
      font-family: "Font Awesome 6 Free";
      font-weight: 900;
      content: "\f00c";
      color: $text-color;
      position: absolute;
      left: 7px;
    }
  }
}
