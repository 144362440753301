/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// Grid settings

@use "sass:math";

$site-container:     1150px;
$narrow-container:   940px;
$spacing-unit:       30px;

// Declare our own spacing unit variable since we recalculate it so often
$half-spacing-unit:  math.div($spacing-unit, 2);
$quarter-spacing-unit: math.div($spacing-unit, 4);

// Set the number of columns in the grid
$grid-columns: 12;
