/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
  A helper that will stick an element to the top of the page:
  `js-stick-to-top` required for JS hook:
  <div class="stick-to-top js-stick-to-top">
    // I will stick to the top of the page
  </div>
*/

.stick-to-top {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: index($z-index-scale, "sticky-top");
}
