/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
  The site header.

  <header class="site-header">
    <a class="site-header__logo"></a>
  </header>

  Modifiers:
    - test-pypi: Adds a 'testing' image in the bg for test PyPI
*/

.site-header {
  background-color: $header-background-color;
  background-repeat: repeat;
  background-position: 0 50%;
  border-bottom: 1px solid $header-border-color;

  &__logo {
    padding: $half-spacing-unit 0;
    max-width: 65px;
    float: left;
    @include link-without-underline;
    @include link-focus-state($white);

    @media screen and (max-width: $small-tablet) {
      max-width: 50px;
    }
  }

  &--test-pypi {
    background-image: url("../images/testing.png");
  }

  &--dev-pypi {
    background-image: url("../images/dev.png");
  }
}
