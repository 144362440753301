/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
  A list of form errors:

  <input aria-describedby="my-field-errors">

  <div id="my-field-errors">
    <ul class="form-errors" role="alert">
      <li>This is a form error</li>
      <li>This is another error</li>
    </ul>
  </div>

  Accessibility:
    - Wrap the error in a div associated with the field via 'aria-describedby'
    - Add 'role=alert'to notify screen readers

  Modifiers:
    - full-width: makes error take entire available horizontal space
*/

.form-errors {
  margin: 5px 0 0;
  padding-bottom: 5px;
  list-style-type: none;
  color: $danger-color;

  li {
    max-width: 350px;

    &::before {
      font-family: "Font Awesome 6 Free";
      font-weight: 900;
      content: "\f06a";
      /*rtl:ignore*/
      margin-right: 5px;
      color: $danger-color;
    }

    a {
      @include danger-underlined-link;
    }
  }

  &--full-width {
    li {
      max-width: 100%;
    }
  }

  .form-error {
    &--valid {
      color: $success-color;

      &::before {
        font-family: "Font Awesome 6 Free";
        font-weight: 900;
        content: "\f00c"; // Check / Tick
        /*rtl:ignore*/
        margin-right: 5px;
        color: $success-color;
      }
    }
  }
}
