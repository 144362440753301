/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
  A badge that indicates that a filter has been applied to search results.
  Includes the description of the filter, and a button to remove the filter.

  <div class="filter-badge">
    <span class="filter-badge__icon">
      // filter icon here
    </span>
    <span class="filter-badge__description">
      // filter description here
    </span>
    <button type="button" class="filter-badge__remove-button">
      // close icon here
    </button>
  </div>
*/

.filter-badge {
  text-transform: uppercase;
  font-weight: 500;
  font-size: $small-font-size;
  border-radius: 2px;
  background-color: $primary-color;
  color: $white;
  display: table;
  float: left;
  margin: 0 5px 10px 0;

  &__icon {
    display: table-cell;
    padding: 6px 0 6px 6px;
    color: $white;
    vertical-align: middle;
  }

  &__description {
    display: table-cell;
    padding: 6px;
    vertical-align: middle;
  }

  &__remove-button {
    display: table-cell;
    color: $white;
    background: transparent;
    border: 0;
    border-left: 1px solid transparentize($white, 0.8);
    padding: 6px;

    &:hover {
      color: $white;
      background-color: darken($primary-color, 5%);
    }

    @include link-focus-state($white);
  }
}
