/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.horizontal-tabs {
  margin-top: 30px;

  &__tabbar {
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: #f1f1f1;

    button {
      background-color: inherit;
      float: left;
      border: none;
      outline: none;
      cursor: pointer;
      padding: 14px 16px;
      transition: 0.3s;

      &:hover {
        background-color: #ddd;
      }

      &.is-active {
        background-color: #006dad;
        color: #fff;
      }
    }
  }

  &__tabcontent {
    display: block;
    padding: 20px;
    border: 1px solid #ccc;
    border-top: none;

    &.is-hidden {
      display: none;
    }
  }
}
