/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
  A password strength indicator:

  <span class="password-strength">
    <span class="password-strength__gauge"></span>
  </span>
*/

.password-strength {
  display: inline-block;
  width: 100%;
  height: 0.8em;
  border: 1px solid $border-color;

  .password-strength__gauge {
    width: 0%;
    height: 100%;
    display: block;

    &--0 {
      width: 20%;
      background-color: $danger-color; // red
    }

    &--1 {
      width: 40%;
      background-color: #ff9918; // orange
    }

    &--2 {
      width: 60%;
      background-color: $highlight-color; // yellow
    }

    &--3 {
      width: 80%;
      background-color: $primary-color; // PyPI blue
    }

    &--4 {
      width: 100%;
      background-color: $success-color; // green
    }
  }
}
