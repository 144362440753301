/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
  A container for filters that are applied to a search result.
  Contains a button to toggle the filter menu on mobile:

  <div class="applied-filters">
    // Filters go here (see filter-badge for details)
    <span class="applied-filters__add-button">
      <button type="button" class="-js-add-filter button button--small">
        Add filter
      </button>
    </div>
  </div>
*/

.applied-filters {
  margin-top: 10px;
  margin-bottom: 15px;
  @include clearfix;

  &__add-button {
    display: none;

    @media only screen and (max-width: $desktop) {
      display: inline-block;
      float: left;
    }
  }
}
