/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
  Layout for warehouse's homepage banner:

  <div class="homepage-banner">
    <h1 class="homepage-banner__title">
      // Title here
    </h1>

    // Search here

    <p class="homepage-banner__browse">
      <a> // Browse link here </a>
    </p>
  </div>
*/

.homepage-banner {
  text-align: center;

  &__title {
    max-width: 810px;
    margin: 0 auto;
    padding: 0 20px 40px;

    @media only screen and (max-width: $mobile) {
      padding: 0 10px $spacing-unit;
    }
  }

  &__browse {
    font-size: 1.2rem;
    margin: $spacing-unit 0 10px 0;

    a {
      @include white-underlined-link;
    }
  }
}
