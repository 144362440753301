/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
  A layout with a left sidebar and main section.

  <div class="left-layout">
    <div class="left-layout__sidebar"></div>
    <div class="left-layout__main"></div>
  </div>
*/

.left-layout {
  @include site-container;

  &__sidebar {
    float: left;
    width: calc(25% - #{$half-spacing-unit});
    margin-right: $spacing-unit;

    @media only screen and (max-width: $desktop) {
      width: 100%;
      margin: 0;
    }
  }

  &__main {
    float: left;
    width: calc(75% - #{$half-spacing-unit});
    margin-right: 0;

    @media only screen and (max-width: $desktop) {
      width: 100%;
      margin: 0;
    }
  }
}
