/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */


/*------------------------------------*\
    #BOX-SIZING
\*------------------------------------*/

/*
  Taken directly from inuit.css
  https://github.com/inuitcss/generic.box-sizing
*/

/**
 * Set the global `box-sizing` state to `border-box`.
 *
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 * paulirish.com/2012/box-sizing-border-box-ftw
 */

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

* {
  &,
  &:before,
  &:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
  }
}
