/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
  Styles a group of frequently asked questions.

  <section class="faq-group">
    <h2>I will have a border applied</h2>
    <h3>I will have a question icon</h3>
  </section>

  Modifiers:
    - list: Modifies h2 margins to compliment uls
*/

.faq-group {
  h2 {
    padding-bottom: 5px;
    margin-top: $spacing-unit * 2;
  }

  h2.faq-group__first {
    margin-top: 0; // Removes margin from first item
  }

  h3 {
    padding-top: 30px;

    &::before {
      font-family: "Font Awesome 6 Free";
      font-weight: 900;
      content: "\f059";
      margin-right: 5px;
      color: $primary-color;
    }
  }

  &--list {
    h2 {
      margin-top: $spacing-unit;
      margin-bottom: 15px;
    }
  }
}
