/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */


// GENERIC - RESET

/*
  Taken directly from inuit.css
  https://github.com/inuitcss/generic.reset
*/

/*
  As well as using normalize.css, it is often advantageous to remove all
  margins from certain elements.
*/

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
dl,
dd,
ol,
ul,
form,
fieldset,
legend,
figure,
table,
th,
td,
caption,
hr {
  margin:  0;
  padding: 0;
}





/*
  Give a help cursor to elements that give extra info on `:hover`.
*/
abbr[title],
dfn[title] {
  cursor: help;
}





/**
 * Remove underlines from potentially troublesome elements.
 */
u,
ins {
  text-decoration: none;
}





/**
 * Apply faux underlines to inserted text via `border-bottom`.
 */
ins {
  border-bottom: 1px solid;
}
