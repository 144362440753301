/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */


/*
  Styling for the font-awesome icons used in Organization management pages.
  The FontAwesome icons are used to indicate the permissions available to a role
  in a table, so are narrowly scoped to prevent conflicts with other icons.

  <tbody class="org-roles">
    <tr>
      <td>
        <i class="fa fa-square-check"></i>
      </td>
      <td>
        <i class="fa fa-square-check"></i>
      </td>
      <td>
        <i class="fa fa-xmark"></i>
      </td>
    </tr>
   </tbody>

 */

tbody.org-roles > tr > td {
  text-align: center;

  .fa-square-check {
    color: green;
  }

  .fa-xmark {
    color: red;
  }
}
