/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// *Big* h1 used for package name, pypi intro
@mixin h1 {
  font-size: 2.5rem;
  line-height: 1.3;
  font-weight: $bold-font-weight;

  // All the other headings resize relative to the body...
  // But for h1s we want a little more control.
  @media screen and (max-width: $tablet) {
    font-size: 2.2rem;
  }

  @media screen and (max-width: $mobile) {
    font-size: 1.8rem;
  }
}

// h1 for all other pages
@mixin h1-title {
  font-size: 1.5rem;
  font-weight: $bold-font-weight;
  padding-bottom: $spacing-unit;
}

@mixin h2 {
  font-size: 1.3rem;
  font-weight: $bold-font-weight;
}

@mixin h3 {
  font-size: 1.15rem;
  font-weight: $bold-font-weight;
}

@mixin h4 {
  font-size: 1.1rem;
  font-weight: $bold-font-weight;
}

@mixin h5 {
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: $bold-font-weight;
}

@mixin h6 {
  font-size: 1rem;
  font-weight: $bold-font-weight;
}
