/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
  A block of author information, found on the author profile sidebar.

  <div class="author-profile">
    // Avatar here (no class required)
    <div class="author-progile__info">
      <h1 class="author-profile__name">
        // Username
      </h1>
      <div class="author-profile__meta"
        <p>
          // Meta
        </p>
      </div>
      <a class="author-profile__edit-button">Edit Profile</a>
    </div>
  </div>
*/

.author-profile {
  padding-bottom: $spacing-unit;

  @media only screen and (min-width: $desktop) {
    padding-right: $spacing-unit;
  }

  @media only screen and (max-width: $desktop) and (min-width: $mobile) {
    padding: 0 0 ($spacing-unit * 1.5);
    display: flex;
    align-items: flex-start;

    img {
      height: 175px;
      margin-right: $spacing-unit;
    }
  }

  @media only screen and (max-width: $desktop) {
    &__info {
      padding-top: 0 !important;
    }
  }

  @media only screen and (max-width: $mobile) {
    img {
      display: none;
      margin-right: 0;
    }
  }

  &__info {
    padding-top: $spacing-unit;
    word-wrap: break-word;
  }

  &__name {
    @include h2;
    padding: 0 0 $half-spacing-unit;
    word-wrap: break-word;

    @media only screen and (max-width: $desktop) {
      border-top: 0;
      margin-top: 0;
      padding-top: 0;
    }
  }

  &__meta {
    margin-bottom: $spacing-unit;
    word-wrap: break-word;
  }

  &__metadiv {
    margin-bottom: $half-spacing-unit;
    word-wrap: break-word;
  }

  &__edit-button {
    margin-bottom: $spacing-unit;
    width: 100%;
    text-align: center;
  }
}
