/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

 @mixin clearfix {
  // From Bourbon. https://www.bourbon.io/docs/latest/#clearfix
  // TODO: Confirm usages across codebase, and remove if not needed.
  // Guidance is to fix layouts with flexbox or grid instead.
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin centered-container($width) {
  max-width: $width;

  margin-left: auto;
  margin-right: auto;
  @include clearfix;
}

@mixin site-container {
  @include centered-container($site-container);
  padding: 0 $spacing-unit;

  @media screen and (max-width: $small-tablet) {
    padding: 0 $half-spacing-unit;
  }
}

@mixin grid-container {
  display: grid;
  grid-template-columns: repeat($grid-columns, 1fr);
  column-gap: $half-spacing-unit;
}

@mixin split-layout {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  > :first-child {
    padding-right: $spacing-unit;
  }
}

@mixin split-table-layout {
  display: table;
  width: 100%;
  table-layout: fixed;

  > :first-child,
  > :last-child {
    float: none;
    display: table-cell;
    vertical-align: middle;
  }
}
