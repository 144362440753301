/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
  A heading aligned in the center, with a sub title and horziontal rule:

  <div class="centered-heading">
    <h1 class="centered-heading__title">Title</h1>
    <p class="centered-heading__subtitle">Subtitle</p>
    <hr class="centered-heading__rule">
  </div>
*/

.centered-heading {
  text-align: center;

  &__title {
    @include h1-title;
    padding-bottom: 0;
  }

  &__rule {
    max-width: 300px;
    margin: $half-spacing-unit auto ($spacing-unit * 2);
    background: $base-grey;

    @media only screen and (max-width: $tablet) {
      display: none;
    }
  }
}
