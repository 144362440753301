/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
  Styles for large, copyable text.

  <p class="copyable">
    <span>// something that needs copied</span>
    <button><i></i></button> // copy icon
  </p>
*/

.copyable {
  @include clearfix;
  padding-bottom: 15px;

  span {
    font-family: $code-font;
    font-weight: 500;
    font-size: 1.2rem;
    float: left;
    background-color: transparentize($black, 0.85);
    padding: 8px;
    border: 1px dotted $transparent-white;
    line-height: $spacing-unit;

    @media only screen and (max-width: $tablet) {
      font-size: 1.1rem;
    }

    @media only screen and (max-width: $mobile) {
      font-size: 1rem;
    }
  }

  button {
    line-height: $spacing-unit;
    float: left;
    cursor: pointer;
    padding: 8px $half-spacing-unit;
    background-color: transparentize($black, 0.85);
    border-right: 1px dotted $transparent-white;
    border-top: 1px dotted $transparent-white;
    border-bottom: 1px dotted $transparent-white;
    border-left: 1px;

    &:hover {
      background-color: transparentize($black, 0.8);
    }

    @include link-focus-state($white);
  }
}
