/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
  Skip to content link for keyboard users.
  Must be the first item inside the page body.
  <a href="#content" class="skip-to-content">Skip to main content</a>
 */

.skip-to-content {
  position: absolute;
  top: auto;
  left: -10000px;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: index($z-index-scale, "skip-to-content");
  @include link-without-underline;

  &:focus {
    top: 7px;
    left: 7px;
    width: auto;
    height: auto;
    line-height: 1;
    padding: $half-spacing-unit;
    color: $primary-color;
    font-weight: $bold-font-weight;
    background-color: $white;
  }
}
