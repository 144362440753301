/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@mixin card {
  border: 1px solid $border-color;
  box-shadow: 1px 1px 2px 1px rgb(0 0 0 / 5%);
  background-color: $white;
  text-decoration: none;

  &:hover,
  &:active {
    border-color: $border-color;
    text-decoration: none;
  }
}

@mixin overlay {
  position: fixed;
  inset: 0;
  background-color: transparentize($black, 0.5);
  z-index: index($z-index-scale, "dark-overlay");
  pointer-events: none;
  transition: opacity 0.3s;
}

@mixin dismiss-button {
  background: transparent;
  border: 0;
  position: absolute;
  top: 16px;
  right: 15px;
  font-size: 18px;
  padding: 0;
  width: 22px;
  height: 22px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;

  &:hover,
  &:active {
    text-decoration: none;
  }
}
