/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
  The sponsor grid on the sponsors page.

  <div class="sponsor-grid sponsor-grid--bottom-margin">
    <div class="sponsor-grid__sponsor">
      <div class="sponsor-grid__sponsor-img">
        <img alt="logo">
      </div>
      <h2>Heading Here</h2>
      <div class="sponsor-grid__sponsor-activity">
        <p>Information about what the sponsor gives us</p>
      </div>
      <a>Visit sponsor</a>
    </div>
  </div>

  Modifiers:
    - bottom-margin: Applies a bottom margin
*/

.sponsor-grid {
  margin-top: $spacing-unit;

  &__sponsor {
    @include card;
    padding: $spacing-unit;
    text-align: center;
    margin-bottom: $spacing-unit;

    &--invitation,
    &--invitation:hover {
      border-color: $primary-color;
    }
  }

  &__sponsor-img {
    margin: 20px 0;
  }

  &__sponsor-name {
    padding-top: 20px;
  }

  &__sponsor-activity {
    font-weight: 600;
    color: lighten($text-color, 10);
    font-size: 1.15rem;
    max-width: 700px;
    margin: 0 auto;
  }

  &__sponsor-link {
    margin-top: $spacing-unit;
  }

  &--bottom-margin {
    margin-bottom: $spacing-unit * 2;
  }
}

@supports (display: grid) {
  .sponsor-grid {
    display: grid;
    grid-gap: $spacing-unit;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

    @media screen and (max-width: $mobile) {
      grid-template-columns: repeat(auto-fill, 100%);
    }

    &__sponsor {
      text-align: left;
      margin-bottom: 0;
      position: relative;
      padding-bottom: 110px;
    }

    &__sponsor-img {
      height: 200px;
      width: 250px;
      max-width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: $mobile) {
        height: 130px;
      }

      img {
        padding: 2em;
      }
    }

    &__sponsor-activity {
      max-width: 100%;
      margin: 0;
    }

    &__sponsor-link {
      width: calc(100% - 60px);
      position: absolute;
      bottom: $spacing-unit;
      display: block;
      text-align: center;
      margin-top: 0;
    }
  }
}
