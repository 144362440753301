/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import "snippet";

/*
  A card that contains information about a organization. Often found in organization lists.
  The card can be an "a" or "div" element, but if it contains a link, choosing
  a top-level "a" element is recommended for accessibility reasons.

  <a class="organization-snippet" href="/link/to/target">
    <h3 class="organization-snippet__title">
      // Package title
      <span class="organization-snippet__version">// Version</span> // Optional!
    </h3>
    <p class="organization-snippet__description">// Description</p>
  </a>
*/

.organization-snippet {
  @include snippet(url("../images/sitemap.png"), url("../images/sitemap.svg"));
}
