/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
  The  description on the package detail page.

  <div class="package-description">
    <p class="package-description__summary">Summary here</p>
    <a class="package-description__edit-button">Optional edit button for package maintainers</a>
  </div>
*/

.package-description {
  direction: ltr;
  min-height: 58px;
  margin-top: -10px;

  &__summary {
    font-size: 1.1rem;
    padding: 0;
    margin-top: 10px;
  }

  &__edit-button {
    margin-top: 10px;
  }
}
