/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
  Horizontal bar that displays statistics on the homepage.

  <div class="statistics-bar">
    <p class="statistics-bar__statistic">
      // Statistic
    </p>
  </div>
*/

.statistics-bar {
  text-align: center;

  &__statistic {
    color: darken($primary-color, 15);
    font-size: 1.2rem;
    display: inline-block;
    padding: 10px 20px;
    margin: 0;
    box-sizing: border-box;

    @media only screen and (max-width: $tablet) {
      font-size: $base-font-size;
    }
  }
}
