/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
  Logo and description found on the homepage:

  <div class="about-pypi">
    <div class="about-pypi__logo">
      // logo here
    </div>
    <div class="about-pypi__text">
      // content here
    </div>
  </div>
*/

.about-pypi {
  @include split-table-layout;
  @include site-container;
  table-layout: auto;
  max-width: 1000px;
  margin: 0 auto;

  &__logo {
    padding-top: 10px;
    padding-right: 50px;

    @media only screen and (max-width: $small-tablet) {
      padding: 0;
    }

    img {
      width: 220px;
      max-width: 100%;
      margin: -$half-spacing-unit 50px 0 0;

      @media only screen and (max-width: $small-tablet) {
        display: none;
      }
    }
  }

  &__text {
    text-align: left;

    @media only screen and (max-width: $small-tablet) {
      display: block;
      width: 100%;
      margin-bottom: -10px;
    }
  }
}
