/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// FORMS

@mixin input-field-active-styles {
  box-shadow: inset 0 0 0 1px $primary-color;
  border-color: $primary-color;
  outline: none;
}

// TODO: Cannot use :where() in the following selector
// because of the order of normalize.css will reset it.
:is(
  input:not([type]),
  select,
  textarea,
  [type="color"],
  [type="date"],
  [type="datetime"],
  [type="datetime-local"],
  [type="email"],
  [type="month"],
  [type="month"],
  [type="number"],
  [type="password"],
  [type="search"],
  [type="tel"],
  [type="text"],
  [type="time"],
  [type="url"],
  [type="week"]
) {
  font-size: $input-font-size;
  border-radius: 2px;
  background-color: $white;
  padding: 7px;
  min-height: 40px;
  border: 1px solid $accessible-border-color;
  color: $text-color;
  vertical-align: middle;
  width: 250px;
  max-width: 100%;

  &:hover,
  &:active,
  &:focus {
    @include input-field-active-styles;
  }
}

input[type="checkbox"] {
  &:focus,
  &:hover,
  &:active {
    @include input-field-active-styles;
  }
}

:where(input:disabled, select:disabled) {
  cursor: not-allowed;
  box-shadow: none;
  background-color: $base-grey;
}

label {
  vertical-align: middle;
}
